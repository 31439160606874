import React, { createContext, useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ContactMail from "../../images/v2/contact_mail.png";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ParagraphTypography from "./Typography/ParagraphTypography";
import Hidden from "@material-ui/core/Hidden";
import PropTypes from "prop-types";

export const ContactUsDialogContext = createContext([false, (value) => value]);

ContactUsDialogContext.displayName = "ContactUsDialogContext";

const useStyle = makeStyles(() => ({
	root: {
		overflow: "unset",
	},
	closeButton: {
		position: "absolute",
		right: -24,
		top: -24,
		background: "#2e3f4f",
		padding: 8,
		"& svg": {
			fontSize: "40px",
			color: "#fff",
		},
		"&:hover": {
			backgroundColor: "#485e73",
		},
	},
	typoContainer: {
		backgroundImage: "linear-gradient(to bottom, #ffffff, #e7f2fe)",
	},
}));

export const ContactUsDialogContextProvider = ({ children }) => {
	const [state, setState] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	const classes = useStyle();

	const handleClose = () => {
		setState(false);
	};

	useEffect(() => {
		if (state && !isLoaded) setIsLoaded(true);
	}, [state]);

	return (
		<ContactUsDialogContext.Provider value={[setState, state]}>
			{children}
			<Dialog
				aria-labelledby="customized-dialog-title"
				// className={classes.root}
				classes={{ paper: classes.root }}
				fullWidth
				keepMounted
				maxWidth="md"
				onClose={handleClose}
				open={state}
				scroll="body"
			>
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
					<CloseIcon />
				</IconButton>
				<Grid container spacing={2}>
					<Grid item md={4}>
						<Hidden smDown>
							<Box className={classes.typoContainer} height="99%" p={4.5} pt={5}>
								<Box alt="Contact Us" component={"img"} height={"auto"} maxWidth={"100%"} src={ContactMail} />
								<ParagraphTypography color="#2e3f4f" fontSize={18} fontWeight={600}>
									Please fill up the form and send it to us. Our sales team will contact you within 24 hrs.
								</ParagraphTypography>
								<Box bgcolor="#0291ae" borderRadius={5} height={5} width={59} />
							</Box>
						</Hidden>
					</Grid>
					<Grid item md={8} sm={12}>
						<Box height={530} p={2}>
							{isLoaded && (
								<Box
									border="none"
									component="iframe"
									height="100%"
									src="https://app.peppercloud.com/form/a812ffea-9ed4-4946-b5e0-67052acf3e97/embed"
									title="Contact Us"
									width="99%"
								/>
							)}
						</Box>
					</Grid>
				</Grid>
			</Dialog>
		</ContactUsDialogContext.Provider>
	);
};

ContactUsDialogContextProvider.propTypes = {
	children: PropTypes.node,
};

export const useContactUsDialog = () => useContext(ContactUsDialogContext);
