import validator from "validator";

const validationService = (ele, value = "") => {
  switch (ele.type) {
    case "text": {
      if (ele.required && !value) {
        return { msg: "Please fill the required field" };
      } else if (!validator.isLength(value, { max: 300 })) {
        return { msg: "Character limit exceeded." };
      } else {
        return {};
      }
    }
    case "phone": {
      if (ele.required && !value) {
        return { msg: "Please fill the required field" };
      } else if (value && !validator.isNumeric(value)) {
        return { msg: "Enter valid number" };
      } else if (value && !validator.isLength(value, { max: 13 })) {
        return { msg: "Check the length of this field" };
      } else if (value && !validator.isLength(value, { min: 6 })) {
        return { msg: "Check the length of this field" };
      } else {
        return {};
      }
    }
    case "email": {
      if (ele.required && !value) {
        return { msg: "Please fill the required field" };
      } else if (!validator.isLength(value, { max: 100 })) {
        return { msg: "Character limit exceeded." };
      } else if (!validator.isEmail(value)) {
        return { msg: "Enter a valid email address" };
      } else {
        return {};
      }
    }
    case "url": {
      if (ele.required && !value) {
        return { msg: "Please fill the required field" };
      } else if (!validator.isLength(value, { max: 100 })) {
        return { msg: "Character limit exceeded." };
      } else if (value && !validator.isURL(value)) {
        return { msg: "Enter a valid URL" };
      } else {
        return {};
      }
    }
    case "multi-url": {
      const splitted = value.split(/[\n,]/);
      if (ele.required && !splitted.length) {
        return { msg: "Please add blog links" };
      } else if (
        splitted.find((each) => !validator.isLength(each, { max: 100 }))
      ) {
        return { msg: "Character limit exceeded." };
      } else if (
        splitted &&
        splitted.length &&
        splitted.find((each) => !validator.isURL(each))
      ) {
        return { msg: "Enter valid URL" };
      } else if (splitted.length < 2) {
        return { msg: "Please add minimum of 3 Blog links" };
      } else {
        return {};
      }
    }
    case "select": {
      if (ele.required && !value) {
        return { msg: "Please select a blog category" };
      } else {
        return {};
      }
    }
    default:
      if (ele.required && !value) {
        return { msg: "Please fill the required field" };
      } else {
        return {};
      }
  }
};

export default validationService;
