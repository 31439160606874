module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-173483086-1","AW-615460128"],"gtagConfig":{"anonymize_ip":false},"pluginConfig":{"head":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pepper Cloud CRM","short_name":"PC_CRM","start_url":"/","background_color":"#FFF","theme_color":"#4592ae","display":"standalone","icons":[{"src":"icons/icon-image-16x16.png","sizes":"16x16","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-image-32x32.png","sizes":"32x32","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-image-72x72.png","sizes":"72x72","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-image-144x144.png","sizes":"144x144","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-image-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-image-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
