import React from "react";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down("sm")]: {
			fontSize: 18,
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 16,
		},
	},
}));

const ParagraphTypography = ({ className, overrideClassName, ...props }) => {
	const classes = useStyles();
	return (
		<Box
			className={overrideClassName ? className : className ? `${className} ${classes.root}` : classes.root}
			color="text.paragraph"
			component="p"
			fontSize={18}
			fontWeight={400}
			{...props}
		/>
	);
};

ParagraphTypography.propTypes = {
	className: PropTypes.string,
	overrideClassName: PropTypes.bool,
};

export default ParagraphTypography;
