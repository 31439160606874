export const CP_Elements = [
  {
    field: "firstName",
    required: true,
    type: "text",
  },
  {
    field: "lastName",
    required: true,
    type: "text",
  },
  {
    field: "email",
    required: true,
    type: "email",
  },
  {
    field: "phone",
    required: true,
    type: "phone",
  },
  {
    field: "website",
    required: false,
    type: "url",
  },
  {
    field: "company",
    required: true,
    type: "text",
  },
  {
    field: "country",
    required: true,
    type: "text",
  },
  {
    field: "comments",
    required: false,
    type: "textarea",
  },
];

export const supportElements = [
  {
    field: "email",
    required: true,
    type: "email",
  },
  {
    field: "company",
    required: true,
    type: "text",
  },
  {
    field: "subject",
    required: true,
    type: "text",
  },
  {
    field: "problem",
    required: true,
    type: "textarea",
  },
];

export const edgElements = [
  {
    field: "name",
    required: true,
    type: "text",
  },
  {
    field: "company",
    required: true,
    type: "text",
  },
  {
    field: "email",
    required: true,
    type: "email",
  },
  {
    field: "phone",
    required: true,
    type: "phone",
  },
];

export const googleAdElements = [
  {
    field: "firstName",
    required: true,
    type: "text",
  },
  // {
  // 	field: "lastName",
  // 	required: true,
  // 	type: "text",
  // },
  {
    field: "email",
    required: true,
    type: "email",
  },
  {
    field: "company",
    required: true,
    type: "text",
  },
  {
    field: "phone",
    required: false,
    type: "phone",
  },
  {
    field: "privacy",
    required: false,
    type: "checkbox",
    default: true,
  },
];

export const guestBlogElements = [
  {
    field: "fullName",
    required: true,
    type: "text",
  },
  {
    field: "email",
    required: true,
    type: "email",
  },
  {
    field: "linkedInProfile",
    required: true,
    type: "url",
  },
  {
    field: "workSamples",
    required: true,
    type: "multi-url",
    minimumVal: 3,
  },
  {
    field: "blogCategory",
    required: true,
    type: "select",
  },
  {
    field: "blogTopics",
    required: true,
    type: "text",
  },
];
