import React from "react";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down("sm")]: {
			fontSize: "22px",
		},
	},
}));

const HeaderTypography = ({ className, overrideClassName, ...props }) => {
	const classes = useStyles();
	return (
		<Box
			className={overrideClassName ? className : className ? `${className} ${classes.root}` : classes.root}
			color="text.secondary"
			component="h3"
			fontFamily="'Poppins', sans-serif"
			fontSize="40px"
			fontWeight="fontWeightBold"
			{...props}
		/>
	);
};

HeaderTypography.propTypes = {
	className: PropTypes.string,
	overrideClassName: PropTypes.bool,
};

export default HeaderTypography;
