import React, { useState } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Link from "../Link";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Clear from "@material-ui/icons/Clear";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Logo from "../../../images/v2/PC-logo.svg";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { useContactUsDialog } from "../ContactUsDialog";
import MuiLink from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
	menuButton: {
		marginRight: theme.spacing(1),
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	drawer: {
		width: theme.drawerWidth,
		maxWidth: "100%",
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: "space-between",
	},
	btnMargin: {
		// marginRight: theme.spacing(1),
		color: "#000000",
		border: "1px solid rgb(0 0 0 / 62%)",
		fontWeight: 600,
		fontSize: 16,
	},
	startBtn: {
		fontWeight: 600,
		fontSize: 16,
		// minWidth: 130,
		"&:hover": {
			color: "#000000",
		},
	},
}));

const Drawer = ({ products, width }) => {
	const classes = useStyles();
	const [openDrawer, setOpenDrawer] = useState(false);

	const [open, setOpen] = React.useState(true);
	const [setOpenTrial] = useContactUsDialog();

	const handleClick = () => {
		setOpen(!open);
	};

	const handleCloseDrawer = () => setOpenDrawer(false);

	return (
		<React.Fragment>
			<Hidden implementation="css" mdUp>
				<IconButton
					aria-label="menu"
					className={classes.menuButton}
					color="inherit"
					edge="start"
					onClick={() => setOpenDrawer(true)}
					size={{ sm: "small", xs: "small" }[width]}
				>
					<MenuIcon />
				</IconButton>
			</Hidden>
			<SwipeableDrawer
				// anchor={anchor}
				classes={{ paper: classes.drawer }}
				onClose={() => setOpenDrawer(false)}
				onOpen={() => setOpenDrawer(true)}
				open={openDrawer}
			// className={classes.drawer}
			>
				<div className={classes.drawerHeader}>
					<Link className={classes.grow} onClick={handleCloseDrawer} to="/">
						<Box display="flex">
							<Box component={"img"} height={{ sm: 40, xs: 34 }[width] || 56} my={1} src={Logo} />
						</Box>
					</Link>
					<IconButton onClick={handleCloseDrawer}>
						<Clear />
					</IconButton>
				</div>
				<Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
					<List>
						<ListItem button onClick={handleClick}>
							<ListItemText primary="Features" />
							{open ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{products.map((each) => (
									<ListItem
										button
										className={classes.nested}
										component={Link}
										key={each.title}
										onClick={handleCloseDrawer}
										to={each.path}
									>
										<ListItemIcon>
											<img alt={each.title} height="25" src={each.img} width="25" />
										</ListItemIcon>
										<ListItemText primary={each.title} />
									</ListItem>
								))}
							</List>
						</Collapse>
						<ListItem button component={MuiLink} href="https://blog.peppercloud.com/" onClick={handleCloseDrawer}>
							<ListItemText primary={"Blog"} />
						</ListItem>
						<ListItem button component={Link} onClick={handleCloseDrawer} to="/careers">
							<ListItemText primary={"Careers"} />
						</ListItem>
						<ListItem button component={Link} onClick={handleCloseDrawer} to="/crm-platfrom-pricing">
							<ListItemText primary={"Pricing"} />
						</ListItem>
						<ListItem button component={Link} onClick={handleCloseDrawer} to="/psg-grant">
							<ListItemText primary={"Grant"} />
						</ListItem>
						{/*<ListItem button href="https://app.peppercloud.com" onClick={handleCloseDrawer}>*/}
						{/*	<ListItemText primary={"Login to CRM"} />*/}
						{/*</ListItem>*/}
						<ListItem button component={Link} onClick={handleCloseDrawer} to="/contact">
							<ListItemText primary={"Contact"} />
						</ListItem>
					</List>

					<Box display="flex" p={2}>
						<Box pr={1} width="50%">
							<Button
								className={classes.btnMargin}
								color="secondary"
								component={Link}
								fullWidth
								onClick={() => {
									handleCloseDrawer();
								}}
								size="small"
								to="/contact"
								variant="outlined"
							>
								Get demo
							</Button>
						</Box>
						<Box pl={1} width="50%">
							<Button
								className={classes.startBtn}
								color="secondary"
								// onClick={() => setOpenTrial(true)}
								fullWidth
								href="https://app.peppercloud.com"
								rel="noreferrer"
								size="small"
								target="_blank"
								variant="contained"
							>
								Login
							</Button>
						</Box>
					</Box>
				</Box>
			</SwipeableDrawer>
		</React.Fragment>
	);
};

Drawer.propTypes = {
	products: PropTypes.array,
	width: PropTypes.string,
};

export default Drawer;
