import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import theme from "../../theme";
import Button from "@material-ui/core/Button";

const modifiedTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: "#FFFFFF",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "initial",
      },
      containedPrimary: {
        color: "#4592ae",
        fontWeight: 600,
        "&:hover": {
          backgroundColor: "#FFFFFF",
          color: "#4592ae",
        },
      },
      containedSizeLarge: {
        minWidth: 300,
        fontSize: 22,
        padding: "12px 28px",
        [theme.breakpoints.down("sm")]: {
          minWidth: 200,
          fontSize: 18,
          padding: "10px 16px",
        },
      },
      outlinedSizeLarge: {
        border: "2px solid #FFFFFF",
        "&:hover": {
          border: "2px solid #FFFFFF",
        },
        minWidth: 300,
        fontSize: 22,
        padding: "12px 28px",
        [theme.breakpoints.down("sm")]: {
          minWidth: 200,
          fontSize: 18,
          padding: "10px 16px",
        },
      },
    },
  },
});

const WhiteButton = (props) => (
  <ThemeProvider theme={modifiedTheme}>
    <Button {...props} />
  </ThemeProvider>
);

export default WhiteButton;
