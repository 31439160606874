import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { Hidden } from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import GenericAdForm from "../components/Form/genericAdForm";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import LogoFavicon from "../images/v2/PC-logo_favicon.svg";
import Logo from "../images/v2/PC-logo.svg";
import callImg from "../images/v2/campaigns/call.svg";
import Divider from "@material-ui/core/Divider";
// import singaporeSmeBannerImg from "../images/v2/campaigns/singapore-sme-banner.png";
import singaporeSmeImg from "../images/v2/campaigns/singapore-sme-banner-img.png";
import singaporeSmeSalesCRMImg from "../images/v2/campaigns/singapore-sme-sales-crm-img.svg";
import pepperCloudCRMHelpsBg from "../images/v2/campaigns/line-bg.svg";
import pepperCloudCRMHelpsContentBg from "../images/v2/campaigns/crm-helps-content-bg.svg";
import singaporeSmeFromImg from "../images/v2/campaigns/watermark.svg";
import salesManagement from "../images/v2/campaigns/sales-process/sales.svg";
import leadManagement from "../images/v2/campaigns/sales-process/lead.svg";
import campaignManagement from "../images/v2/campaigns/sales-process/campaign.svg";
import salesForcasting from "../images/v2/campaigns/sales-process/forecast.svg";
import teamManagement from "../images/v2/campaigns/sales-process/team.svg";
import securityManagement from "../images/v2/campaigns/sales-process/security.svg";
import formSuccessImg from "../images/v2/campaigns/form-success-img.svg";
import useWidth from "../hooks/useWidth";
import PCLandingPageTestimonial from "../components/v2/HomeSlider/PCLandingPageTestimonial";
import requestDemoBGImg from "../images/v2/campaigns/request-demo-bg.svg";
import loadable from "@loadable/component";
import WhiteButton from "../components/v2/WhightButton";
import IntegratedCRMBgImg from "../images/v2/home/integrated-crm-bg.svg";
import PCBenefits from "../images/v2/campaigns/PCBenefits.png";
import SEO from "../components/SEO";

export const conversionEventOnCall = () => {
  if (typeof window.gtag === "function")
    window.gtag("event", "conversion", {
      send_to: "AW-615460128/prVoCM7gjuwBEKDavKUC",
    });
};

const useStyles = makeStyles((theme) => ({
  marginAuto: {
    margin: "0 auto",
  },
  header: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.29)",
    padding: ".65rem 0 .25rem",
  },
  singaporeSmeBanner: {
    backgroundImage:
      "linear-gradient(to bottom, #00b8d8, #28c2de 72%, #edf5fe, #ffffff)",
  },
  singaporeSmeFromBox: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "3.5rem",
    },
  },
  singaporeSmeFrom: {
    borderRadius: "10px",
    boxShadow: "0 2px 5px 0 #b7b7b7",
    background: `#fff url(${singaporeSmeFromImg}) no-repeat bottom`,
    maxWidth: "440px",
    backgroundSize: "430px 522px",
    position: "relative",
    zIndex: "1",
    margin: "0 auto",
  },
  singaporeSmeFromHeader: {
    background: "#2e3f4f",
    borderRadius: "10px 10px 0 0",
    padding: ".6rem .25rem",
    letterSpacing: ".32px",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  salesProcessCard: {
    border: "none",
    boxShadow: "none",
    // backgroundColor: "transparent",
    marginBottom: theme.spacing(4),
  },
  cardHeaderAvatar: {
    height: 100,
    width: 100,
    background: "#FFF",
    boxShadow: "1px 1px 7px 0 rgb(0 0 0 / 19%)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: -68,
  },
  colorChange: {
    color: "#ff7a59",
    fontSize: "30px",
    fontWeight: "700",
    letterSpacing: ".4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  cardHeader: {
    background: "#f2f7fd",
    marginLeft: 54,
    // [theme.breakpoints.down("sm")]: {
    // 	alignItems: "end !important",
    // },
  },
  IntegratedCRMBg: {
    background: `url(${IntegratedCRMBgImg}) center bottom no-repeat`,
    backgroundSize: "cover",
  },
  whitespaceNowrap: {
    whiteSpace: "nowrap",
  },
  pepperCloudCRMHelps: {
    [theme.breakpoints.up("md")]: {
      background: `url(${pepperCloudCRMHelpsBg}) no-repeat`,
      backgroundSize: "100%",
      backgroundPosition: "center",
    },
  },
  pepperCloudCRMHelpsSpan: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#ffffff",
      padding: "0 1rem",
    },
  },
  pepperCloudCRMHelpsContent: {
    background: `url(${pepperCloudCRMHelpsContentBg}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  pepperCloudCRMHelpsContentListBox: {
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#ffffff",
    padding: "2rem 1rem",
  },
  pepperCloudCRMHelpsContentList: {
    listStyle: "none",
    padding: "0px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  pepperCloudCRMHelpsContentListItem: {
    display: "inline-block",
    paddingRight: "1rem",
    marginRight: "1rem",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none !important",
    },
    "&:not(:last-of-type)": {
      borderRight: "1px solid rgba(112, 112, 112, .5)",
    },
  },
  pepperCloudCRMHelpsContentListItemSpan: {
    fontSize: "36px",
    color: "#088937",
    paddingRight: "1rem",
    lineHeight: "normal",
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  listItemTextTitle: {
    color: "#2e3f4f",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    [theme.breakpoints.up("xs")]: {
      fontSize: "18px",
		},
    [theme.breakpoints.up("sm")]: {
			marginBottom:"0px",
      fontSize: "12px",
		},
    [theme.breakpoints.up("md")]: {
			marginBottom:"0px",
     fontSize: "18px",
		}

  },
  listItemTextDescription: {
    fontSize: "16px",
    color: "#2e3f4f",
    paddingBottom: "20px", 
    [theme.breakpoints.up("xs")]: {
			paddingBottom: "30px", 
      fontSize: "18px",
		},
    [theme.breakpoints.up("sm")]: {
			paddingBottom: "0px",
      fontSize: "12px",
		},
    [theme.breakpoints.up("md")]: {
			paddingBottom: "25px",
     fontSize: "16px",
		}
  },
  listStyle: {
    color: " #0698b4",
  },
  listContainer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  PCBenefits: {
    position: `relative`,
    background: `transparent url(${PCBenefits}) no-repeat center`,
    backgroundSize: "contain",
    backgroundPosition: "initial",
    width: "100%",
    height: "768px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "407.7px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "417px",
    },
  },
  PCBenefitsText: {
    width: "488px",
    fontFamily: "Poppins",
    fontSize: "35px",
    fontWeight: "600",
    textAlign: "left",
    color: "#fff",
    paddingTop: "264px",
    paddingLeft: "54px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "147px",
      paddingLeft: "12px",
      width: "100%",
      fontSize: "28px",
    },
    [theme.breakpoints.down("sm")]: {
			width: '308px',
			paddingTop: "166px",
      paddingLeft: "23px",
	    fontSize: "25px",
    },
  },
  PCBenefitsList: {
    paddingTop: "70px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "-38px",
		},
    [theme.breakpoints.up("md")]: {
      marginTop:"12px"
		}
  },
}));
const CompanyCarousel = loadable(() =>
  import("../components/v2/CompanyCarousel")
);
const LazyLoad = loadable(() => import("react-lazyload"));
const GoogleAdV2 = () => {
  const [success, setSuccess] = React.useState(false);
  const handleSuccess = () => {
    setSuccess(true);
  };
  const classes = useStyles();

  const width = useWidth();
const benefitsList = [
  {
    title: "Increase your Sales",
    description: (
      <>
        Make your sales team more effective by helping them close deals faster.
        Track your sales teams performance in real-time.
      </>
    ),
  },
  {
    title: "Local Support",
    description: (
      <>
        Get 24*7 local support. Our experienced CRM consultants will work with
        your Sales team to optimise your team performance.
      </>
    ),
  },
  {
    title: "All-Integrated Product",
    description: (
      <>
        Integrate your email account, calendar, WhatsApp, SMS, and accounting
        software with Pepper Cloud CRM. Get all the information in one place
        when you need it.
      </>
    ),
  },
  {
    title: "Affordable",
    description: (
      <>
        Experience easy and simple CRM at an affordable price with Pepper Cloud.
        Pay per user and scale as per your requirements.
      </>
    ),
  },
  {
    title: "Avail PSG Grant",
    description: (
      <>
        Claim upto 50% PSG Grant for Pepper Cloud CRM. Leave all the tedious
        paperwork to us.{" "}
      </>
    ),
  },
  {
    title: "Save time and efforts of your team",
    description: (
      <>
        Provide your sales team with real-time alerts, automate lead capture,
        assign tasks, and create events. Eliminate manual data entry through
        enrichment provided by CRM.
      </>
    ),
  },
];
  return (
    <React.Fragment>
      {/* Header  */}
      <SEO>
        <meta content="noindex" name="robots" />
        <meta content="noindex" name="googlebot" />
      </SEO>
      <Box className={classes.header}>
        <Box component={Container}>
          <Box alignItems="center" display="flex" justifyContent="flex-start">
            <Box alignItems="center" display="flex">
              <Link to="/">
                <Box
                  alt="PepperCloud logo"
                  component={"img"}
                  height={{ sm: 36, xs: 36 }[width] || 58}
                  mr={{ sm: 1, xs: 1 }[width] || 2}
                  src={LogoFavicon}
                />
              </Link>
              <HeaderTypography
                className="header"
                color="#2e3f4f"
                fontSize={{ sm: 13, xs: 13 }[width] || 18}
                fontWeight={600}
                m={0}
                mr={{ sm: 2, xs: 1 }[width] || 4}
                overrideClassName
              >
                Sales CRM Demo
              </HeaderTypography>
            </Box>
            <MuiLink href="tel:+6597510475" onClick={conversionEventOnCall}>
              <Box alignItems="center" display="flex">
                <Box
                  alt="Call to PepperCloud"
                  component={"img"}
                  height={{ sm: 14, xs: 14 }[width] || 20}
                  mr={{ sm: 0, xs: 0 }[width] || 1}
                  src={callImg}
                />
                <HeaderTypography
                  className={classes.whitespaceNowrap}
                  color="#0291ae"
                  fontSize={{ sm: 14, xs: 14 }[width] || 18}
                  fontWeight={600}
                  m={0}
                  overrideClassName
                >
                  +65 9751 0475
                </HeaderTypography>
              </Box>
            </MuiLink>
          </Box>
        </Box>
      </Box>

      {/* Banner */}
      <Box className={classes.singaporeSmeBanner}>
        <Container>
          <Grid alignItems="flex-start" container justify="center">
            <Grid item md={7} sm={12}>
              <Box>
                <HeaderTypography
                  fontSize={{ sm: 22, xs: 20 }[width] || 44}
                  mb={0}
                  mt={6}
                  overrideClassName
                >
                  Goodbye Spreadsheets,
                </HeaderTypography>
                <HeaderTypography
                  fontSize={{ sm: 36, xs: 34 }[width] || 62}
                  lineHeight={{ sm: 2, xs: 2 }[width] || "normal"}
                  my={0}
                  overrideClassName
                >
                  Hello CRM!
                </HeaderTypography>
                <ParagraphTypography
                  color="#ffffff"
                  fontFamily="'Poppins', sans-serif"
                  fontSize={34}
                  fontWeight={500}
                  mb={3}
                  mt={0}
                >
                  Time to upgrade to Pepper Cloud <br />A powerful CRM to drive
                  your sales
                </ParagraphTypography>
                <Box
                  alt="Sales CRM Singapore"
                  component={"img"}
                  maxHeight="330px"
                  maxWidth="100%"
                  src={singaporeSmeImg}
                />
              </Box>
            </Grid>
            <Grid item md={5} sm={12}>
              <Box
                className={classes.singaporeSmeFromBox}
                px={1}
                textAlign="center"
              >
                <Box className={classes.singaporeSmeFrom}>
                  <HeaderTypography
                    className={classes.singaporeSmeFromHeader}
                    color="#fff"
                    component="h1"
                    fontWeight={600}
                    m={0}
                    textAlign="center"
                  >
                    Avail up to <b className={classes.colorChange}>50%</b> EDG
                    grant
                  </HeaderTypography>
                  {success ? (
                    <Box p={8} textAlign="center">
                      <Box
                        alt="Pepper Cloud form success"
                        component={"img"}
                        mr={1}
                        src={formSuccessImg}
                        width="120px"
                      />
                      <HeaderTypography fontSize={18} my={2}>
                        Thank you for contacting us!
                      </HeaderTypography>
                      <ParagraphTypography
                        color="#2e3f4f"
                        fontSize={16}
                        letterSpacing={0.56}
                        lineHeight={1.56}
                        m={0}
                      >
                        We have received your enquiry and we will respond to you
                        within 24 hours.
                      </ParagraphTypography>
                      <br />
                      <Link to="/">
                        <HeaderTypography
                          color="#0291ae"
                          fontSize={14}
                          fontWeight={600}
                          m={0}
                        >
                          Click here to visit home page
                        </HeaderTypography>
                      </Link>
                    </Box>
                  ) : (
                    <GenericAdForm
                      handleSuccess={handleSuccess}
                      width={width}
                    />
                  )}
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    py={2}
                  >
                    <Box fontFamily="Poppins" fontSize={14} mr={3}>
                      Call/WhatsApp
                    </Box>
                    <MuiLink
                      href="tel:+6597510475"
                      onClick={conversionEventOnCall}
                    >
                      <Box alignItems="center" display="flex">
                        <Box
                          alt="Call to PepperCloud"
                          component={"img"}
                          height={{ sm: 14, xs: 14 }[width] || 20}
                          mr={1}
                          src={callImg}
                        />
                        <HeaderTypography
                          className={classes.whitespaceNowrap}
                          color="#0291ae"
                          fontSize={{ sm: 13, xs: 13 }[width] || 14}
                          fontWeight={600}
                          m={0}
                          overrideClassName
                        >
                          +65 9751 0475
                        </HeaderTypography>
                      </Box>
                    </MuiLink>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Content*/}
      <Box pb={8} pt={{ sm: 8, xs: 8 }[width] || 15} textAlign="center">
        <Container>
          <Box className={classes.marginAuto} maxWidth={960}>
            <HeaderTypography fontSize={35} mb={3} mt={0}>
              Turbocharge your sales team with Pepper Cloud CRM
            </HeaderTypography>
            <ParagraphTypography>
              Manage your leads, sales pipeline, and customers in one place;
              streamline your sales operations; harness the power of the cloud
              CRM software; get 24*7 local support
            </ParagraphTypography>
          </Box>
          <Box
            alt="Sales CRM Singapore"
            component={"img"}
            maxWidth="100%"
            src={singaporeSmeSalesCRMImg}
          />
        </Container>
      </Box>

      {/* Content */}
      <Box textAlign="center">
        <Container>
          <HeaderTypography
            className={classes.pepperCloudCRMHelps}
            fontSize={35}
            mt={0}
          >
            <span className={classes.pepperCloudCRMHelpsSpan}>
              Pepper Cloud CRM helps you to
            </span>
          </HeaderTypography>
        </Container>
      </Box>

      {/* Content */}
      <Box
        className={classes.pepperCloudCRMHelpsContent}
        py={{ sm: 4, xs: 3 }[width] || 8}
      >
        <Container>
          <Box className={classes.pepperCloudCRMHelpsContentListBox}>
            <ul className={classes.pepperCloudCRMHelpsContentList}>
              <li className={classes.pepperCloudCRMHelpsContentListItem}>
                <Box alignItems="center" display="flex">
                  <span
                    className={classes.pepperCloudCRMHelpsContentListItemSpan}
                  >
                    1
                  </span>
                  Close deals 5x faster
                </Box>
              </li>
              <li className={classes.pepperCloudCRMHelpsContentListItem}>
                <Box alignItems="center" display="flex">
                  <span
                    className={classes.pepperCloudCRMHelpsContentListItemSpan}
                  >
                    2
                  </span>
                  Double your leads
                </Box>
              </li>
              <li className={classes.pepperCloudCRMHelpsContentListItem}>
                <Box alignItems="center" display="flex">
                  <span
                    className={classes.pepperCloudCRMHelpsContentListItemSpan}
                  >
                    3
                  </span>
                  Makes your sales team 3x more efficient
                </Box>
              </li>
              <li className={classes.pepperCloudCRMHelpsContentListItem}>
                <Box alignItems="center" display="flex">
                  <span
                    className={classes.pepperCloudCRMHelpsContentListItemSpan}
                  >
                    4
                  </span>
                  Digitally transform your business
                </Box>
              </li>
            </ul>
          </Box>
        </Container>
      </Box>

      {/* content */}
      <Box className={classes.salesProcessCardGroup} pb={4}>
        <Container>
          <Box className={classes.marginAuto} maxWidth={760}>
            <HeaderTypography
              fontSize={{ sm: 24, xs: 24 }[width] || 35}
              mt={6}
              textAlign="center"
            >
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Build your business in a whole new way with the most trusted sales
              CRM for SMEs
            </HeaderTypography>
          </Box>

          <br />

          <Box className={classes.marginAuto} maxWidth="1100px">
            {[
              {
                name: "Sales Management",
                img: salesManagement,
                description: `Manage all sales opportunities as they progress through your custom sales process. Capture every interaction with your customers and close the deals faster.`,
              },
              {
                name: "Lead Management",
                img: leadManagement,
                description: `Use our inbuilt webforms to capture the leads through your website and social media channels. Qualify your leads, track them efficiently, and convert them into opportunities.`,
              },
              {
                name: "Campaign Management",
                img: campaignManagement,
                description: `Never miss out on a chance to engage with your leads. Segment your leads, craft your message and blast through the CRM.`,
              },
              {
                name: "Sales Forecasting",
                img: salesForcasting,
                description: `Get a clear view of your sales numbers. Get real-time sales projections and focus on opportunities that matter. Categorize sales opportunities based on the product, user, customer type, etc.`,
              },
              {
                name: "Team Management",
                img: teamManagement,
                description: `Get your entire sales team on CRM. Create sales teams by territories, product lines, etc. Use roles and profiles to restrict data access across teams. Track team sales performance on various metrics.`,
              },
              {
                name: "Security Management",
                img: securityManagement,
                description: `Activate 2FA (Two Factor Authentication) for better protection of your confidential customer data.`,
              },
            ].map((each) => (
              <Card className={classes.salesProcessCard} key={each.name}>
                <CardHeader
                  avatar={
                    <Box
                      alt={each.name}
                      component={"img"}
                      height="53px"
                      src={each.img}
                    />
                  }
                  classes={{
                    root: classes.cardHeader,
                    avatar: classes.cardHeaderAvatar,
                  }}
                  subheader={
                    <ParagraphTypography
                      color="#2e3f4f"
                      fontSize={14}
                      letterSpacing={0.56}
                      lineHeight={1.56}
                      m={0}
                      overrideClassName
                    >
                      {each.description}
                    </ParagraphTypography>
                  }
                  title={
                    <HeaderTypography
                      fontSize={{ sm: 18, xs: 18 }[width] || 18}
                      fontWeight={600}
                      mb={1}
                      mt={0}
                      overrideClassName
                    >
                      {each.name}
                    </HeaderTypography>
                  }
                />
              </Card>
            ))}
          </Box>
        </Container>
      </Box>

    <Grid container xs={12} className={classes.listContainer}>
        <Grid item xs={12} sm={5}>
          <Box className={classes.PCBenefits}>
            <Box className={classes.PCBenefitsText}>
              Here is how you can benefit with Pepper Cloud CRM
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} width="100%">
          <Box className={classes.PCBenefitsList}>
            <ul className={classes.listStyle}>
              {benefitsList.map((item, index) => {
                return (
                  <li>
                    <Box className={classes.listItemTextTitle}>
                      {item.title}
                    </Box>
                    <Box className={classes.listItemTextDescription}>
                      {item.description}
                    </Box>
                  </li>
                );
              })}
            </ul>
          </Box>
        </Grid>
      </Grid>


      {/* clients Sections */}
      <Box>
        <Container>
          <HeaderTypography
            className={classes.pepperCloudCRMHelps}
            fontSize={35}
            my={4}
            textAlign="center"
          >
            <span className={classes.pepperCloudCRMHelpsSpan}>
              Many Singapore companies are growing with us
            </span>
          </HeaderTypography>
          <br />
          <br />
          <CompanyCarousel className={classes.cimg} />
        </Container>
      </Box>

      <Box>
        <Container>
          <Box mb={4} mt={10}>
            <Divider dark />
          </Box>
        </Container>
      </Box>

      {/* Testimonials */}
      <Box className={classes.IntegratedCRMBg}>
        <Container id="testimonial">
          <Box display="flex" justifyContent="center">
            <Box
              maxWidth={800}
              pb={{ sm: 2, xs: 2 }[width] || 6}
              pt={4}
              textAlign="center"
            >
              <HeaderTypography
                alignItems="center"
                display="flex"
                fontSize={35}
                justifyContent="center"
                lineHeight={1.2}
                mb={3}
                mt={0}
              >
                Here is why our customers love us
              </HeaderTypography>
            </Box>
          </Box>
          <Container>
            <LazyLoad offset={100} once>
              <PCLandingPageTestimonial dotPosition="bottom" minHeight={550} />
            </LazyLoad>
          </Container>
          {/*</Hidden>*/}
        </Container>
      </Box>

      {/* Book a free demo */}
      <Box className={classes.requestDemoRoot} py={10} textAlign="center">
        <Box alignItems="center" display="flex" justifyContent="center">
          <HeaderTypography
            color="common.white"
            fontSize={35}
            maxWidth={858}
            my={0}
            textAlign="center"
          >
            Join our happy customers and increase your sales revenue
          </HeaderTypography>
        </Box>
        <Box mt={6} textAlign="center">
          <WhiteButton
            color="primary"
            component={Link}
            href="#"
            size="large"
            variant="contained"
          >
            Book a Free Demo
          </WhiteButton>
        </Box>
      </Box>

      {/* footer */}
      <Box py={5}>
        <Container>
          <Box textAlign="center">
            <Link to="/">
              <Box
                alt="Pepper Cloud - Best WhatsApp CRM Software"
                component={"img"}
                height="40px"
                src={Logo}
              />
            </Link>
            <ParagraphTypography
              color="#2e3f4f"
              fontFamily="Poppins"
              fontSize={{ sm: 14, xs: 14 }[width] || 20}
              m4={0}
            >
              #34-04, Tower 1, One Raffles Place, Singapore 048616
            </ParagraphTypography>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default GoogleAdV2;
