import React, { Component } from "react";
import axios from "axios";
import validator from "validator";
import validationService from "./validationService";
import { googleAdElements as formElements } from "./formElements";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Box, Button } from "@material-ui/core";
import { Link } from "gatsby";
import ParagraphTypography from "../v2/Typography/ParagraphTypography";
import "./form.scss";
import qs from "querystring";

export const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export default class FormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        privacy: true,
      },
      error: {},
      marketingDetailsState: {},
    };
  }

  componentDidMount() {
    let marketingDetails = {
      url: "",
      utmCampaign: "",
      utmSource: "",
      utmMedium: "",
    };

    const [url, queries] = window.location.href.split("?");
    marketingDetails.url = url;
    if (queries) {
      const queryObj = qs.parse(queries);
      if (queryObj.utm_campaign)
        marketingDetails.utmCampaign = queryObj.utm_campaign;
      if (queryObj.utm_source) marketingDetails.utmSource = queryObj.utm_source;
      if (queryObj.utm_medium) marketingDetails.utmMedium = queryObj.utm_medium;
    }
    this.setState({ marketingDetailsState: marketingDetails });
  }
  handleCheckBox = () =>
    this.setState((prev) => ({
      data: { ...prev.data, privacy: !prev.data.privacy },
    }));

  handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    const { data } = this.state;
    this.setState({ data: { ...data, [name]: value } });
  };

  handleBlur = (e) => {
    const {
      target: { name, value },
    } = e;
    const { data, error } = this.state;
    const sanitizedInput = validator.trim(value);
    const field = formElements.find((e) => e.field === name);
    const validation = validationService(field, sanitizedInput);
    this.setState({
      data: { ...data, [name]: sanitizedInput },
      error: { ...error, [name]: validation.msg },
    });
  };

  validate = () => {
    let isValid = true;
    let { error, data } = this.state;
    formElements.forEach((ele) => {
      const sanitizedInput =
        typeof data[ele.field] === "string"
          ? validator.trim(data[ele.field] || "")
          : data[ele.field];
      const validation = validationService(ele, sanitizedInput);

      error = { ...error, [ele.field]: validation.msg };

      data = { ...data, [ele.field]: sanitizedInput };

      if (validation.msg) {
        isValid = false;
      }
    });

    this.setState({ data, error });

    return isValid;
  };

  handleSubmit = () => {
    const isValid = this.validate();
    if (isValid) {
      try {
        // eslint-disable-next-line no-unused-vars
        fetch("/.netlify/functions/contact-us", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            type: this.props.mailchimp ? "MAILCHIMP" : "GOOGLE ADS",
            ...this.state.data,
            ...this.state.marketingDetailsState,
          }),
        }).then(() => {
          if (typeof window.gtag === "function")
            window.gtag("event", "conversion", {
              send_to: "AW-615460128/F9KUCL_Oxb0CEKDavKUC",
            });
          // eslint-disable-next-line react/prop-types
          this.props.handleSuccess();
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  };

  render() {
    const { error } = this.state;
    return (
      <Box className="pc-google-ad-content-form" p={2}>
        <form autoComplete="off" noValidate>
          {[
            {
              key: "firstName",
              label: "Name",
              required: true,
            },
            // {
            // 	key: "lastName",
            // 	label: "Last Name",
            // 	required: true,
            // },
            {
              key: "email",
              label: "Email",
              required: true,
            },
            {
              key: "company",
              label: "Company",
              required: true,
            },
            {
              key: "phone",
              label: "Phone",
              required: false,
            },
          ].map(({ key, ...props }) => (
            <TextField
              error={Boolean(error[key] || "")}
              fullWidth
              helperText={error[key] || ""}
              key={key}
              margin="normal"
              name={key}
              onChange={this.handleChange}
              size="small"
              value={this.state.data[key] || ""}
              variant="outlined"
              {...props}
            />
          ))}

          <Box textAlign="left">
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.data.privacy}
                  name="checkbox"
                  onChange={this.handleCheckBox}
                />
              }
              label={
                <ParagraphTypography
                  color="#343434"
                  fontSize={14}
                  overrideClassName
                >
                  I confirm and agree to the storing and processing of my
                  personal data as described in the
                  <Box display="inline-block" ml={0.5}>
                    <Link to="/privacy">Privacy Statement.</Link>
                  </Box>
                </ParagraphTypography>
              }
            />
          </Box>
          <Box my={2} textAlign="center">
            <Button
              className={`book-demo-btn ${
                this.props.className ? this.props.className : ""
              }`}
              color="secondary"
              onClick={this.handleSubmit}
              size="large"
              variant="contained"
            >
              Book a Free Demo
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
}
