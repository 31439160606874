import React from "react";
import TopLayout from "./TopLayout";
import { Location } from "@reach/router";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/source-sans-pro/400.css";
import "@fontsource/source-sans-pro/600.css";
import "@fontsource/source-sans-pro/700.css";

export const wrapRootElement = ({ element }) => {
	return <Location>{({ location }) => <TopLayout pathname={location.pathname}>{element}</TopLayout>}</Location>;
};

// export const onRouteUpdate = ({ pathname, setBodyProps }) => {
// 	setBodyProps({ pathname });
// };
