import React from "react";
import Box from "@material-ui/core/Box";
import HeaderTypography from "../Typography/HeaderTypography";
import ParagraphTypography from "../Typography/ParagraphTypography";
import Link from "../Link";
import HomeSlider from "./index";
import { makeStyles } from "@material-ui/core/styles";
import testimonialOne from "../../../images/v2/testimonials-images/yoon-kam-fei-peppercloud-customer.png";
import testimonialFour from "../../../images/v2/testimonials-images/judith-adao-peppercloud-customer.png";
import testimonialSix from "../../../images/v2/testimonials-images/pantana-na-takuathung-peppercloud-customer.png";
import testimonialSeven from "../../../images/v2/testimonials-images/wilson-tan-peppercloud-customer.jpg";
import clientLogoOne from "../../../images/v2/client-logos/tech-data-pepper-cloud-customer.png";
import clientLogoFour from "../../../images/v2/client-logos/mustard-seed-system-corporation-pepper-cloud-customer.png";
import clientLogoFive from "../../../images/v2/client-logos/sis-distribution-pepper-cloud.png";
import useWidth from "../../../hooks/useWidth";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import quotesImg from "../../../images/v2/testimonials-quotes.svg";
import testimonialBgImg from "../../../images/v2/testimonial-bg.svg";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  sliderTestimonialImg: {
    boxShadow: "7px 25px 25px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "10px",
  },
  link: {
    cursor: "pointer",
    marginLeft: theme.spacing(1),
  },
  testimonialCustomerImg: {
    maxWidth: "80%",
    width: "330px !important",
    borderRadius: "10px",
    boxShadow: "0 15px 50px 0 rgba(0, 0, 0, 0.16)",
    marginTop: "2.65rem",
    marginLeft: "3rem",
  },
  testimonialBg: {
    background: `url(${testimonialBgImg}) no-repeat`,
    backgroundSize: "195px 223px",
  },
  HomeSliderWrapper: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px !important",
    },
  },
}));

const PCLandingPageTestimonial = ({
  handleClick,
  isReadMore,
  onlyIndex,
  ...props
}) => {
  const width = useWidth();
  const classes = useStyles();
  const slides = [
    // {
    // 	index: 0,
    // 	img: testimonialTwo,
    // 	clientLogo: clientLogoTwo,
    // 	name: "SS Lim",
    // 	position: "Director",
    // 	company: "PTC Systems, Singapore",
    // 	contentSmall: `“We found Pepper Cloud as a simple and straight forward solution for small and growing companies. It lets you focus on the next sale right away.”`,
    // 	content: (
    // 		<React.Fragment>
    // 			“Pepper Cloud is a strong sales tracking tool. With its attractive sales pipeline view, we visually track and
    // 			plan all our opportunities from start to finish. This simple CRM is intuitive and comes with various
    // 			applications integrations such as Office 365 and Mailchimp.
    // 			<br />
    // 			<br />
    // 			Pepper Cloud is a simple and straightforward CRM for SMEs. We found it easy to set up and run, and its simple
    // 			UI was helpful to let us focus on the next sale right away.”
    // 		</React.Fragment>
    // 	),
    // },
    // {
    // 	index: 0,
    // 	img: testimonialSeven,
    // 	clientLogo: clientLogoFive,
    // 	name: "Wilson Tan",
    // 	position: "Senior Manager",
    // 	company: "Singapore Maritime Foundation, Singapore",
    // 	contentSmall: "",
    // 	content: (
    // 		<React.Fragment>
    // 			“Pepper Cloud is a good CRM tool to improve coordination among SMF departments and improve our database
    // 			management.
    // 			<br />
    // 			<br />
    // 			The user-friendly interface, easy-to-use functions, and customisable features meant we could set up,
    // 			integrate, and onboard the whole team on the cloud platform quickly.
    // 			<br />
    // 			<br />
    // 			We appreciate the support and responsiveness of the Pepper Cloud team during the implementation process!”
    // 		</React.Fragment>
    // 	),
    // },
    {
      index: 1,
      img: testimonialSeven,
      clientLogo: clientLogoFive,
      name: "Wilson Tan",
      position: "Senior Manager",
      company: "Singapore Maritime Foundation, Singapore",
      contentSmall: "",
      content: (
        <React.Fragment>
          “Pepper Cloud is a good CRM tool to improve coordination among SMF
          departments and improve our database management.
          <br />
          <br />
          The user-friendly interface, easy-to-use functions, and customisable
          features meant we could set up, integrate, and onboard the whole team
          on the cloud platform quickly.
          <br />
          <br />
          We appreciate the support and responsiveness of the Pepper Cloud team
          during the implementation process!”
        </React.Fragment>
      ),
    },
    {
      index: 2,
      img: testimonialOne,
      clientLogo: clientLogoOne,
      name: "Yoon Kam Fei",
      position: "Managing Director",
      company: "Tec D Distribution Sdn Bhd, Malaysia",
      contentSmall: `“Pepper Cloud CRM is a right mix of flexibility, affordability and ease of use. It is built for your sales team, who can use it to optimize closure time and manage sales pipeline.”`,
      content: (
        <React.Fragment>
          “Team collaboration, accurate sales forecasting, and effective
          resource management are the challenges every organisation encounters.
          But having an efficient CRM tool like Pepper Cloud enhances
          productivity while assisting in optimising the sales process and
          managing the sales pipeline.
          <br />
          <br />
          Pepper Cloud CRM tool is the right mix of flexibility, affordability,
          and ease of use.
        </React.Fragment>
      ),
    },
    {
      index: 3,
      img: testimonialSix,
      clientLogo: clientLogoFive,
      name: "Pantana Na Takuathung",
      position: "General Manager",
      company: "SiS Distribution (Thailand) Public Co., Ltd",
      contentSmall: `“Pepper Cloud CRM tool is an excellent all-in-one tool for a team like us who run multiple businesses across multiple domains. It boosts the productivity of our team by helping us focus on core processes.”`,
      content: (
        <React.Fragment>
          “Pepper Cloud is an excellent all-in-one CRM tool to run multiple
          businesses across multiple domains. Through multiple sales pipelines,
          we track the business opportunities and the conversations between our
          team and our customers. Furthermore, this CRM software boosts the
          productivity of our team by helping us focus on core processes.”
        </React.Fragment>
      ),
    },
    // {
    // 	index: 3,
    // 	img: testimonialThree,
    // 	clientLogo: clientLogoThree,
    // 	name: "Anthony Gerald",
    // 	position: "Group CEO",
    // 	company: "Cuscapi Berhad, Malaysia",
    // 	contentSmall: `“Pepper Cloud CRM has a strong sales pipeline management tool that helps us to manage our business operations in 6 countries. What I personally like the most is their service dedication and personal touch.”`,
    // 	content: (
    // 		<React.Fragment>
    // 			“We were very clear from the start that the CRM we would implement must be simple to work with and super easy
    // 			to set up. We also evaluated CRM providers in terms of after sales support and service. Finally, we chose
    // 			Pepper Cloud CRM after due consideration and now it is paying off well!
    // 			<br />
    // 			Pepper Cloud CRM has a strong sales pipeline management tool that helps us to manage our business operations
    // 			in 6 countries. It has a robust user management system with clearly defined access levels for data security
    // 			and protection, which we needed.
    // 			<br />
    // 			What I personally like the most in Pepper Cloud CRM is their service dedication and personal touch. There is a
    // 			face behind the product to whom we can reach out to whenever there is a problem. Looking for answers in help
    // 			articles and training videos was the last thing we wanted to do.
    // 			<br />
    // 			Pepper Cloud CRM has also added new features based on our feedback. This makes all the difference in the
    // 			successful adoption of a product.”
    // 		</React.Fragment>
    // 	),
    // },
    {
      index: 4,
      img: testimonialFour,
      clientLogo: clientLogoFour,
      name: "Judith M. Adao",
      position: "General Manager",
      company: "Mustard Seed Systems Corporation, Philippines",
      contentSmall: `“Pepper Cloud CRM is a must for every growing sales organization. Very simple, yet it gives all the information needed to manage the sales leads.”`,
      content: (
        <React.Fragment>
          “Pepper Cloud is a simple and easy to use CRM tool and it gives all
          the information needed to manage the sales leads. The CRM also helps
          us to run data driven marketing campaigns.
          <br />
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Pepper Cloud's customer service and local support are incredible and
          helped us at every stage of the implementation. This CRM is a must for
          every growing sales organization.”
        </React.Fragment>
      ),
    },
    // {
    // 	index: 5,
    // 	img: testimonialFive,
    // 	clientLogo: clientLogoOne,
    // 	name: "Sally See",
    // 	position: "Marketing Manager",
    // 	company: "Tec D Distribution Sdn Bhd, Malaysia",
    // 	contentSmall: `“Pepper Cloud CRM has made it easier for the updated information to be accessible to everyone in our company. It helps us to track all present and past interactions with the customer.”`,
    // 	content: (
    // 		<React.Fragment>
    // 			Pepper Cloud CRM has made it easier for the updated information to be accessible to everyone in our company.
    // 			Its association feature allows linking the business and activity records, helping us to track all present and
    // 			past interactions with the customer giving sales access to more information, allowing a higher level
    // 			opportunity conversion.
    // 			<br />
    // 			Our marketing team also utilizes that information to run targeted marketing campaigns. The CRM is good not
    // 			only in tracking and forecasting sales but also in providing custom graphs, page views and fields. Another
    // 			good thing with Pepper Cloud CRM is that they take feedback seriously. We feel that we do have a say in the
    // 			product roadmap.”
    // 		</React.Fragment>
    // 	),
    // },
  ];

  const BottomTestimonial = ({ client: each }) => (
    <Box
      component={Grid}
      container
      justify="center"
      mb={"30px !important"}
      spacing={2}
    >
      <Grid item md={5} sm={5} xs={12}>
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <Box className={classes.testimonialBg} display="inline-block">
            <img
              alt={each.name}
              className={classes.testimonialCustomerImg}
              src={each.img}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item md={1} sm={1} xs={12}>
        <Divider light orientation="vertical" />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <Box>
          <Box alt="Quote Image" component="img" src={quotesImg} width={33} />
          <ParagraphTypography
            fontSize={
              { sm: "16px !important", xs: "16px !important" }[width] || 18
            }
            lineHeight={1.7}
            mb={4}
            textAlign="left"
          >
            {each.content}
          </ParagraphTypography>
          <Box fontSize={18} fontWeight="fontWeightBold" textAlign="left">
            {each.name}
          </Box>
          <ParagraphTypography fontSize={16} mt={0} textAlign="left">
            {each.position}
            {each.company ? `, ${each.company}` : ""}
          </ParagraphTypography>
        </Box>
      </Grid>
    </Box>
  );

  BottomTestimonial.propTypes = {
    client: PropTypes.object,
  };

  if (typeof onlyIndex !== "undefined") {
    const client = slides.find((each) => each.index === onlyIndex);
    if (client) return <BottomTestimonial client={client} />;
  }

  return (
    <HomeSlider {...props}>
      {slides.map((each) =>
        props.dotPosition === "bottom" ? (
          <BottomTestimonial client={each} />
        ) : (
          <Box
            alignItems={{ sm: "center", xs: "center" }[width] || "flex-end"}
            className={classes.HomeSliderWrapper}
            display="flex"
            flexDirection="column"
            key={each.name}
            mt={6}
            pr={3}
          >
            <Box
              alt={each.name}
              className={classes.sliderTestimonialImg}
              component="img"
              maxWidth="100%"
              mb={2}
              src={each.img}
              width={{ sm: 200, xs: 185 }[width] || 350}
            />
            <Box
              alt={each.company}
              component="img"
              height="auto"
              maxHeight={35}
              my={1.5}
              src={each.clientLogo}
              width="auto !important"
            />
            <HeaderTypography
              fontSize={
                { sm: "20px !important", xs: "20px !important" }[width] || 14
              }
              fontWeight={500}
              my={1}
            >
              <b>{each.name},</b> {each.position}
            </HeaderTypography>
            <ParagraphTypography
              color="#151d41"
              fontSize={18}
              fontStyle="italic"
              lignHeight={1.7}
              mb={8}
              mt={2}
            >
              {isReadMore ? (
                <React.Fragment>
                  {/*{each.content.slice(0, 170)}*/}
                  {each.contentSmall}
                  {/*{"..."}*/}
                  <Link
                    className={classes.link}
                    component="span"
                    href="/#testimonial"
                    onClick={handleClick}
                  >
                    Read More
                  </Link>
                </React.Fragment>
              ) : (
                each.content
              )}
            </ParagraphTypography>
          </Box>
        )
      )}
    </HomeSlider>
  );
};

PCLandingPageTestimonial.propTypes = {
  handleClick: PropTypes.func,
  isReadMore: PropTypes.bool,
  onlyIndex: PropTypes.number,
  dotPosition: PropTypes.string,
};

export default PCLandingPageTestimonial;
