import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";
import "./carousel.css";

const HomeSlider = ({ children }) => {
	return (
		<Carousel autoPlay infiniteLoop showArrows={false} showStatus={false} showThumbs={false}>
			{children}
		</Carousel>
	);
};

HomeSlider.propTypes = {
	children: PropTypes.node,
	dotPosition: PropTypes.string,
	minHeight: PropTypes.any,
};

export default HomeSlider;
